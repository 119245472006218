import detect from './detect';

var wa = ""; // 네이버페이 자바스크립트 유입코드

document.write("<script type=\"text/javascript\" src=\"https://wcs.naver.net/wcslog.js\"></script>");
document.write("<script type=\"text/javascript\">");
document.write("if(!wcs_add){ var wcs_add = {}; wcs_add[\"wa\"] = \"" + wa + "\"; wcs.inflow(\"" + window.location.hostname + "\"); }");
document.write("</script>");

switch(detect){
    case "pc": {
        document.write('<script type="text/javascript" src="https://pay.naver.com/customer/js/naverPayButton.js" charset="UTF-8"></script>');
        break;
    }
    case "mobile": {
        document.write('<script type="text/javascript" src="https://pay.naver.com/customer/js/mobile/naverPayButton.js" charset="UTF-8"></script>');
        break;
    }
}
